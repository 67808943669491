<template>
  <div class="chart-container-in">
    <apexchart
      class="custom-chart"
      type="radialBar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    series: Array,
    labels: Array,
    text: String,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'radialBar',
          offsetY: -10,
          background: 'transparent',
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: {
              background: '#00000050',
            },
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: 'white',
                formatter: (val) => {
                  return val + ' ' + this.text; // Use arrow function to preserve 'this'
                }
              }
            }
          }
        },
        stroke: {
          dashArray: 4,
          background: '#000',
        },
        colors: ['var(--accent-color)'],
        labels: [],
        states: {
          hover: {
            filter: {
              type: 'none',
              value: 0
            }
          }
        },
      },
    };
  },
  created() {
    this.chartOptions.labels = this.labels;
  },
};
</script>