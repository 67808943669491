<template>
  <div>
    <h2>File Manager</h2>
    <div class="upload-container">
      <label for="file-upload" class="button glowing custom-file-upload">
        Upload File
      </label>
      <input id="file-upload" type="file" @change="uploadFile">
      <p>Total: {{ storageSpace.total }} GB</p>
      <p>Available: {{ storageSpace.available }} GB</p>
    </div>
    <div v-if="uploading" class="upload-info">
      <div class="upload-stats">
        <p>Speed: {{ uploadSpeed }} MB/s</p>
        <p>Remaining: {{ remainingMB }} MB</p>
        <p>ETA: {{ eta }} s</p>
      </div>
      <div class="progress-bar">
        <div class="progress-bar-fill" :style="{ width: uploadProgress + '%' }"></div>
      </div>
    </div>
    <div class="file-grid">
      <div class="file-item" v-for="fileObj in files" :key="fileObj.filename" :style="getFileItemStyle(fileObj)">
        <div class="file-name">{{ getName(fileObj.filename) }}</div>
        <div class="file-overlay"></div>
        <div class="file-actions">
          <button @click="downloadFile(fileObj.filename)">
            <img :src="downloadSvg" width="24" height="24" alt="Download icon">
          </button>
          <button @click="deleteFile(fileObj.filename)">
            <img :src="trashSvg" width="24" height="24" alt="Trash Bin icon">
          </button>
          <button @click="shareFile(fileObj.filename)">
            <img :src="shareSvg" width="24" height="24" alt="Share icon">
          </button>
        </div>
      </div>
    </div>
    <!-- <div v-if="shareLink" class="share-link">
      <h3>Shareable Link (valid for 24 hours)</h3>
      <p>{{ shareLink }}</p>
    </div> -->
  </div>
</template>



<script>
import trashSvg from '../../assets/trash-bin.svg';
import downloadSvg from '../../assets/download.svg';
import shareSvg from '../../assets/share.svg';

export default {
  data() {
    return {
      files: [],
      shareLink: null,
      trashSvg,
      shareSvg,
      downloadSvg,
      uploading: false,
      uploadProgress: 0,
      uploadSpeed: 0,
      remainingMB: 0,
      eta: 0,
      startTime: null,
      uploadedBytes: 0,
      storageSpace: {
        available: 0,
        total: 0,
      },
    };
  },
  mounted() {
    this.fetchFiles();
    this.fetchStorageSpace();
  },
  methods: {
    async uploadFile(event) {
      const file = event.target.files[0];
      if (!file) return;
      
      const formData = new FormData();
      formData.append('file', file);
      
      this.uploading = true;
      this.uploadProgress = 0;
      this.startTime = Date.now();
      this.uploadedBytes = 0;
      
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `https://${process.env.VUE_APP_SERVER_IP}:5301/upload`, true);
      xhr.setRequestHeader('authorization', localStorage.getItem('token'));
      
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const currentTime = Date.now();
          const timeElapsed = (currentTime - this.startTime) / 1000;
          
          this.uploadedBytes = event.loaded;
          const uploadedMB = event.loaded / (1024 * 1024);
          const remainingBytes = event.total - event.loaded;
          const remainingMB = remainingBytes / (1024 * 1024);

          this.uploadProgress = Math.round((event.loaded / event.total) * 100);
          this.uploadSpeed = (uploadedMB / timeElapsed).toFixed(2);
          this.remainingMB = remainingMB.toFixed(2);
          this.eta = (remainingMB / this.uploadSpeed).toFixed(2);
        }
      };
      
      xhr.onload = () => {
        this.uploading = false;
        if (xhr.status === 200) {
          const result = JSON.parse(xhr.responseText);
          console.log(result.message);
          this.fetchFiles();
        } else {
          console.error('Error uploading file:', xhr.statusText);
        }
      };
      
      xhr.onerror = () => {
        this.uploading = false;
        console.error('Error uploading file:', xhr.statusText);
      };
      
      xhr.send(formData);
    },
    async fetchFiles() {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/files`, {
          headers: { 'authorization': localStorage.getItem('token') },
        });
        const result = await response.json();

        this.files = result.files.map(file => ({
          ...file,
          thumbnailUrl: null,
        }));

        this.fetchThumbnails();
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    },
    async fetchThumbnails() {
      const fetchPromises = this.files.map(async (fileObj) => {
        if (fileObj.isImage) {
          try {
            const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/thumbnails/${fileObj.filename}`, {
              headers: { 'authorization': localStorage.getItem('token') },
            });
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            fileObj.thumbnailUrl = blobUrl;
          } catch (error) {
            console.error('Error fetching thumbnail:', error);
          }
        }
      });
      await Promise.all(fetchPromises);
    },
    getFileItemStyle(fileObj) {
      if (fileObj.thumbnailUrl) {
        return {
          backgroundImage: `url(${fileObj.thumbnailUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
      }
      return {
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
      };
    },
    isImage(filename) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
      const fileExtension = filename.substring(filename.lastIndexOf('.')).toLowerCase();
      return imageExtensions.includes(fileExtension);
    },
    async fetchStorageSpace() {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/storage-space`, {
          headers: { 'authorization': localStorage.getItem('token') }
        });
        const result = await response.json();
        this.storageSpace = {
          available: (result.available / (1024 * 1024 * 1024)).toFixed(2),
          total: (result.total / (1024 * 1024 * 1024)).toFixed(2),
        };
      } catch (error) {
        console.error('Error fetching storage space:', error);
      }
    },
    async deleteFile(filename) {
      try {
        await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/delete/${filename}`, {
          method: 'DELETE',
          headers: { 'authorization': localStorage.getItem('token') }
        });
        this.files = this.files.filter(fileObj => fileObj.filename !== filename);
      } catch (error) {
        console.error('Error deleting file:', error);
      } 
    },
    async downloadFile(filename) {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/download/${filename}`, {
          headers: { 'authorization': localStorage.getItem('token') }
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    async shareFile(filename) {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/share/${filename}`, {
          method: 'POST',
          headers: { 'authorization': localStorage.getItem('token') }
        });
        const result = await response.json();
        this.shareLink = result.link;
        navigator.clipboard.writeText(result.link);
      } catch (error) {
        console.error('Error sharing file:', error);
      }
    },
    getName(file) {
      const index = file.indexOf('-');
      if (index === -1) {
        return file;
      }
      return file.substring(index + 1);
    }
  },
  beforeUnmount() {
    this.files.forEach(fileObj => {
      if (fileObj.thumbnailUrl) {
        URL.revokeObjectURL(fileObj.thumbnailUrl);
      }
    });
  },
};
</script>

<style scoped>
.button {
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  transition: ease 0.3s;
  margin-top: 0;
}

.upload-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}


.upload-container p {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ffffffd2;
  border-radius: 10px;
  color: #ffffffab;
}

input[type="file"] {
  display: none;
}

.file-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  width: 150px;
  height: 100px;
  transition: transform 0.2s, height 0.2s;
  position: relative;
  overflow: hidden;
}

.file-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.file-item:hover {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  height: 124px;
  transform: translateY(8px);
}

.file-name {
  margin-top: 10px;
  text-align: center;
  word-break: break-all;
  z-index: 1;
}

.file-actions {
  display: none;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 10px;
}

.file-item:hover .file-actions {
  display: flex;
}

.file-actions button {
  padding: 5px 10px;
  color: #fff;
  gap: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.file-actions button:hover {
  background-color: transparent;
}

.file-actions button:hover img {
  opacity: 0.7;
}

.share-link {
  margin-top: 20px;
}

.progress-bar {
  background-color: #f3f3f32f;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.progress-bar-fill {
  height: 10px;
  background-color: #ffffff;
  width: 0;
  transition: width 0.2s;
}

.upload-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.upload-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-right: 50px;
}
.upload-stats p {
  margin: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .file-actions {
    display: flex;

  }
}
</style>