<template>
  <div class="login-box" :class="{ 'shake': loginFailed }">
    <h2>Sign In</h2>
    <form @submit.prevent="login">
      <div class="user-box" :class="{ 'error': loginFailed }">
        <input type="text" id="username" placeholder="Username" v-model="username" />
      </div>

      <div class="user-box" :class="{ 'error': loginFailed }">
        <input type="password" id="password" placeholder="Password" v-model="password" />
      </div>

      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      loginFailed: false,
    };
  },
  methods: {
    async login() {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-login-key': process.env.VUE_APP_LOGIN_KEY,
          },
          body: JSON.stringify({
            username: this.username,
            password: this.password,
          }),
        });

        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('token', data.token);
          this.$emit('login', data.token);
        } else {
          console.error(`Login failed: ${data.error}`);
          this.username = '';
          this.password = '';
          this.loginFailed = true;
          setTimeout(() => {
            this.loginFailed = false;
          }, 500);
        }
      } catch (error) {
        console.error('Login failed:', error);
        this.loginFailed = true;
      }
    },
  },
};
</script>

<style scoped>

.login-box {
  position: relative;
  margin: auto;
  width: 30%;
  padding: 40px;
  background: var(--main-background-color);
  box-sizing: border-box;
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box .user-box input::placeholder {
  color: rgba(255, 255, 255, 0.297);
}
.login-box .user-box input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff;
}

.user-box.error input {
  border-bottom: 1px solid red !important;
}
.login-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box button {
  margin-top: 40px;
  width: fit-content;
  border: 1px solid #fff;
}

.login-box button:hover {
  box-shadow: 0 0 20px 0px #ffffff28;
  transition: ease-in-out 0.2s;
  background: white;
  color: black;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s;
}

@media (max-width: 768px) {
  .login-box {
    width: 100%;
  }
  .login-box button {
    width: 100%;
    text-align: center;
  }
}


</style>