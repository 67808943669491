<template>
  <div id="app" :class="{ 'blur': gpuTier && gpuTier.tier >= 3 }">
    <template v-if="loading"></template>
    <template v-else>
      <template v-if="authenticated">
        <div class="app-container">
          <!-- Sidebar -->
          <div v-if="!isMobile || showSidebar" class="side-panel">
            <!-- Close Sidebar Button (Mobile Only) -->
            <button v-if="isMobile" class="close-sidebar" @click="showSidebar = false">Close Sidebar</button>
            <h1>Dashboard</h1>
            <div
              v-for="(category, categoryIndex) in categories"
              :key="categoryIndex"
              :class="{ 'expanded': category.expanded }"
            >
              <h2 @click="toggleCategory(categoryIndex)" :class="{ 'hide-line': !category.expanded }">
                {{ category.name }}
              </h2>
              <transition-group
                name="button-fade"
                @before-enter="beforeEnter"
                @enter="enter"
                @leave="leave"
              >
                <div v-if="category.expanded">
                  <custom-button
                    class="nav-button"
                    v-for="(button, buttonIndex) in category.buttons"
                    :key="buttonIndex"
                    :button-text="button.text"
                    :button-class="isTabActive(button.name) ? 'button active' : 'button'"
                    :button-icon="button.icon"
                    @click="changeTab(button.name)"
                  ></custom-button>
                </div>
              </transition-group>
            </div>
            <custom-button
              :button-text="'Logout'"
              :button-class="'button glowing'"
              :button-icon="logoutSvg"
              @click="logout"
            ></custom-button>
          </div>
          <!-- Main Screen -->
          <div v-if="!isMobile || !showSidebar" class="main-screen">
            <!-- Open Sidebar Button (Mobile Only) -->
            <button v-if="isMobile" class="open-sidebar" @click="showSidebar = true">Open Sidebar</button>
            <router-view></router-view>
          </div>
        </div>
      </template>
      <template v-else>
        <Login @login="checkTokenValidity" />
      </template>
    </template>
  </div>
</template>

<script>
import Login from './components/pages/Login.vue';
import CustomButton from './components/elements/CustomButton.vue';
import VanillaTilt from 'vanilla-tilt';
import { getGPUTier } from 'detect-gpu';
import gsap from 'gsap';

import homeSvg from './assets/home.svg';
import terminalSvg from './assets/terminal.svg';
import ftpSvg from './assets/ftp.svg';
import settingsSvg from './assets/settings.svg';
import noteSvg from './assets/note.svg';
import nodeSvg from './assets/node.svg';
import airdropSvg from './assets/airdrop.svg';

export default {
  components: {
    Login,
    CustomButton,
  },
  data() {
    return {
      loading: true,
      authenticated: false,
      gpuTier: null,
      currentTab: 'home',
      tilt: null,
      logoutSvg: require('./assets/logout.svg'),
      isMobile: false,
      showSidebar: false, // Controls sidebar visibility on mobile

      categories: [
        {
          name: 'Personal',
          expanded: true,
          buttons: [
            { text: 'Home', name: 'home', icon: homeSvg },
            { text: 'Notes', name: 'notes', icon: noteSvg },
            { text: 'Files', name: 'files', icon: ftpSvg },
            { text: 'AirDrop', name: 'airdrop', icon: airdropSvg },
          ],
        },
        {
          name: 'Server',
          expanded: false,
          buttons: [
            { text: 'Stats', name: 'stats', icon: homeSvg },
            { text: 'Terminal', name: 'terminal', icon: terminalSvg },
            { text: 'Process', name: 'process', icon: nodeSvg },
            { text: 'FTP', name: 'example', icon: ftpSvg },
          ],
        },
        {
          name: 'Settings',
          expanded: false,
          buttons: [
            { text: 'General', name: 'settings', icon: settingsSvg },
            { text: 'Advanced', name: 'example', icon: settingsSvg },
          ],
        },
      ],
    };
  },

  async beforeMount() {
    this.gpuTier = await getGPUTier();
    await this.initializeTiltEffect();
  },
  mounted() {
    // Initialize isMobile and showSidebar
    this.isMobile = window.innerWidth <= 768;
    this.showSidebar = !this.isMobile;

    // Add resize event listener
    window.addEventListener('resize', this.handleResize);

    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      this.checkTokenValidity(storedToken);
    } else {
      this.loading = false;
    }

    const storedImage = localStorage.getItem('backgroundImage');
    if (storedImage) {
      document.body.style.backgroundImage = `url('${storedImage}')`;
    }

    const accentColor = localStorage.getItem('accentColor');
    if (accentColor) {
      document.documentElement.style.setProperty('--accent-color', accentColor);
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.tilt.destroy();
  },

  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(-10px)';
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.3,
        onComplete: done,
      });
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        y: -10,
        duration: 0.3,
        onComplete: done,
      });
    },
    toggleCategory(index) {
      this.categories[index].expanded = !this.categories[index].expanded;
    },
    changeTab(tab) {
      this.$router.push({ name: tab });
      if (this.isMobile) {
        this.showSidebar = false; // Hide sidebar on mobile after navigation
      }
    },
    isTabActive(tab) {
      return this.$route.name === tab;
    },
    async initializeTiltEffect() {
      if (this.gpuTier.tier >= 3 && this.gpuTier.isMobile === false) {
        this.tilt = VanillaTilt.init(document.querySelector('#app'), {
          max: 1,
          speed: 400,
          glare: false,
        });
      }
    },
    async checkTokenValidity(token) {
      try {
        const response = await fetch(
          `https://${process.env.VUE_APP_SERVER_IP}:5301/protected-resource`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (response.ok) {
          this.authenticated = true;
        } else {
          this.authenticated = false;
          console.error('Token validation error:', response.statusText);
        }
      } catch (error) {
        console.error('Token validation error:', error);
      } finally {
        this.loading = false;
      }
    },
    async logout() {
      localStorage.removeItem('token');
      this.authenticated = false;
    },
    handleResize() {
      const wasMobile = this.isMobile;
      this.isMobile = window.innerWidth <= 768;
      if (this.isMobile !== wasMobile) {
        this.showSidebar = !this.isMobile;
      }
    },
  },
};
</script>

<style>
.app-container {
  display: flex;
  width: 100vw; /* Ensure it takes full width */
}

.side-panel {
  width: 250px;
  overflow-y: auto;
}

.main-screen {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Mobile Styles */
@media (max-width: 768px) {
  #app {
    width: 100% !important;
    height: 100vh !important;
    margin: 0 !important;
    background-color: rgba( 0, 0, 0, 0.15 );
  }

  .side-panel,
  .main-screen {
    width: 80% !important;
    background-color: rgba( 0, 0, 0, 0) !important;
    border: none !important;
  }

  .open-sidebar,
  .close-sidebar {
    display: block;
    padding-left: 0px;
    font-style: italic;
    opacity: 0.6;
  }
}

.blur .main-screen,
.blur .side-panel,
.blur .login-box {
  backdrop-filter: blur(7.5px);
}
</style>
