<template>
  <div>
    <h2>Stats</h2> 
    <h3 v-if="systemInfo && networkInfo">{{ systemInfo.model }}, Online</h3>
    <h3 v-else>Loading datas...</h3>
    <div class="chart-container">
      <CustomRadialBar :series="serieCPU" :labels="chartLabelCPU" :text="'%'" />
      <CustomRadialBar :series="serieRAM" :labels="chartLabelRAM" :text="'%'" />
      <CustomRadialBar :series="serieTemp" :labels="chartLabelTemp" :text="'°C'" />
      <CustomRadialBar :series="serieDisk" :labels="chartDiskTemp" :text="'%'" />
    </div>

    <div class="device-info">
      <h2>Device Info</h2>
      <div class="separator"></div>
      <div class="info">
        <h3>Device Name</h3>
        <p>{{ systemInfo && systemInfo.model }}</p>
      </div>
      <div class="separator"></div>
      <div class="info">
        <h3>Device Manufacturer</h3>
        <p>{{ systemInfo && systemInfo.manufacturer }}</p>
      </div>
      <div class="separator"></div>
      <div class="info">
        <h3>IP Address</h3>
        <p>{{ networkInfo && networkInfo.ip4 }}</p>
      </div>
      <div class="separator"></div>
      <div class="info">
        <h3>Public IP Address</h3>
        <p>{{ publicIp }}</p>
      </div>
      <div class="separator"></div>
      <div class="info">
        <h3>MAC Address</h3>
        <p>{{ networkInfo && networkInfo.mac }}</p>
      </div>
      <div class="separator"></div>
      <div class="info">
        <h3>Uptime</h3>
        <p>{{ uptime }}</p>
      </div>
      <div class="separator"></div>
      <div class="info">
        <h3>Region</h3>
        <p>Europe</p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomRadialBar from '../elements/CustomRadialBar.vue';

export default {
  components: {
    CustomRadialBar,
  },
  data() {
    return {
      systemInfo: null,
      networkInfo: null,
      monitorInfo: null,
      publicIp: null,
      uptime: "0d 0h 0m",
      serieCPU: [0],
      serieRAM: [0],
      serieTemp: [0],
      serieDisk: [0],
      chartLabelCPU: ['CPU Usage'],
      chartLabelRAM: ['RAM Usage'],
      chartLabelTemp: ['Temperature'],
      chartDiskTemp: ['Disk Usage'],
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchSystemInfo();
      vm.startFetchingMonitorData();
    });
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalId);
    next();
  },
  methods: {
    async fetchSystemInfo() {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/system-info`, {headers: { 'authorization': localStorage.getItem('token') }});
        if (response.ok) {
          const data = await response.json();
          this.systemInfo = data.systemInfo;
          this.networkInfo = data.networkInfo[1];
          this.publicIp = data.publicIp;
        } else {
          console.error('Failed to fetch system info');
        }
      } catch (error) {
        console.error('Error fetching system info', error);
      }
    },

    async fetchMonitorData() {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/monitoring-info`, {headers: { 'authorization': localStorage.getItem('token') }});
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          this.monitorInfo = data;
          this.serieCPU = [Math.round(data.cpuUsage * 1000) / 10];
          this.serieRAM = [Math.round((1 - data.freeMemPercentage) * 1000) / 10];
          if (data.temperature.main)
            this.serieTemp = [Math.round(data.temperature.main * 100) / 100];
          this.serieDisk = [Math.round(data.diskInfo[0].use * 1000) / 1000];
          this.uptime = this.formatTime(data.uptime);
        } else {
          console.error('Failed to fetch monitor data');
        }
      } catch (error) {
        console.error('Error fetching monitor data', error);
      }
    },

    startFetchingMonitorData() {
      this.fetchMonitorData();
      this.intervalId = setInterval(this.fetchMonitorData, 5000);
    },

    formatTime(seconds) {
      const days = Math.floor(seconds / (24 * 3600));
      const hours = Math.floor((seconds % (24 * 3600)) / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      let result = '';

      if (days > 0) {
        result += `${days}d `;
      }

      if (hours > 0 || days > 0) {
        result += `${hours}h `;
      }

      result += `${minutes}m`;

      return result.trim();
    },
  },
};
</script>