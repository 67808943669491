<template>
  <div class="terminal">
    <div class="output" ref="output">
      <div v-html="formattedOutput"></div>
      <span style="color: var(--accent-color);">{{ lastPrompt }}</span>
      <input
        ref="commandInput"
        v-model="input"
        @keyup.enter="sendCommand"
        @keydown="handleKeyDown"
        class="command-input"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ws: null,
      input: '',
      output: '',
      lastPrompt: '',
      lastCommand: 'Undefined',
      commandHistory: [],
      historyIndex: -1,
      disabledCommands: [
        'exit', 'reboot', 'shutdown', 'logout', 'poweroff',
        'su', 'kill', 'pkill', 'rm -rf /', 'iptables', 'ifconfig', 'route',
        'dd', 'mkfs', 'fdisk', 'wget', 'curl', 'ssh', 'scp', 'rsync',
        'nc', 'ncat', 'chmod', 'chown', 'systemctl', 'service',
        'modprobe', 'insmod', 'sysctl'
      ],
    };
  },
  computed: {
    formattedOutput() {
      const lines = this.output.split('\n');
      return lines.map(line => {
        if (line.match(/^[^@]+@[^:]+:.+ \$ /)) {
          return `<span style="color: var(--accent-color);">${line}</span>`;
        } else if (line.includes('-bash:')) {
          return `<span style="color: #ff6b6b;">${line}</span>`;
        } else {
          return line;
        }
      }).join('<br>');
    },
  },

  mounted() {
    this.ws = new WebSocket(`wss://${process.env.VUE_APP_SERVER_IP}:5301`);

    this.ws.onmessage = (event) => {
      let lines = event.data.split('\n');

      if (lines[0].trim() === this.lastCommand) {
        lines.shift();
      }

      this.lastPrompt = lines[lines.length - 1];
      lines.pop();

      this.output += lines.join('\n') + '\n';
    };

    this.ws.onclose = () => {
      this.output += 'Connection closed.\n';
    };

    this.ws.onerror = (error) => {
      this.output += `Error: ${error.message}\n`;
    };

    this.$nextTick(() => {
      this.$refs.commandInput.focus();
    });
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'ArrowUp') {
        this.navigateHistory('up');
        event.preventDefault(); // Prevent cursor from moving to the start
      } else if (event.key === 'ArrowDown') {
        this.navigateHistory('down');
        event.preventDefault(); // Prevent cursor from moving to the end
      } else if (event.key !== 'Enter') {
        // Reset history index if the user starts typing a new command
        this.historyIndex = -1;
      }
    },
    navigateHistory(direction) {
      if (direction === 'up') {
        if (this.historyIndex < this.commandHistory.length - 1) {
          this.historyIndex++;
        }
      } else if (direction === 'down') {
        if (this.historyIndex > 0) {
          this.historyIndex--;
        } else {
          this.historyIndex = -1;
          this.input = '';
          return;
        }
      }
      this.input = this.commandHistory[this.commandHistory.length - 1 - this.historyIndex] || '';
    },
    sendCommand() {
      if (this.input.trim()) {
        this.lastCommand = this.input.trim();
        this.commandHistory.push(this.lastCommand);
        this.historyIndex = -1;
        if (this.disabledCommands.includes(this.input.trim())) {
          this.output += `${this.lastPrompt}${this.input}\nCommand "${this.input.trim()}" is disabled.\n`;
        } else if (this.input.trim() === 'clear') {
          this.output = '';
        } else {
          this.output += `${this.lastPrompt}${this.input}`;
          this.ws.send(this.input + '\n');
        }
        this.input = '';
        this.$nextTick(() => {
          this.$refs.commandInput.focus();
        });
      }
    },
  },
};
</script>

<style scoped>
.terminal {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0);
  color: white;
  font-size: 16px;
  padding: 10px;
}
.output {
  flex: 1;
  overflow-y: auto;
}
.input-line {
  display: flex;
}
.command-input {
  border: none;
  background: transparent;
  color: white;
  font-size: 16px;
  flex: 1;
  display: inline;
}
.command-input:focus {
  outline: none;
}
</style>
