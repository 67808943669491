<template>
  <draggable-resizable-vue v-model:x="x" v-model:y="y" v-model:w="width" v-model:h="height" v-model:active="active" class="note-container">
    <div
      :key="componentKey"
      ref="editable"
      contenteditable="true"
      class="editable-area"
      @input="saveText"
      @blur="applyMarkdown"
      @focus="editMarkdown"
      @keydown="handleDelete"
      v-html="renderedContent"
    ></div>
    <div v-if="!active" class="tags-container">
      <span v-for="tag in currentTags" :key="tag" class="tag">{{ tag }}</span>
    </div>
  </draggable-resizable-vue>
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
  props: ['id', 'initialX', 'initialY', 'initialWidth', 'initialHeight', 'text', 'tags'],
  data() {
    return {
      x: this.initialX,
      y: this.initialY,
      width: this.initialWidth,
      height: this.initialHeight,
      currentText: this.text,
      active: false,
      md: new MarkdownIt(),
      renderedContent: '',
      componentKey: 0,
      isDeleting: false,
      currentTags: this.tags
    };
  },
  mounted() {
    this.restoreTags();
    this.applyMarkdown();
  },
  methods: {
    saveText() {
      if (this.isDeleting) return;
      this.currentText = this.$refs.editable.innerText;
    },
    applyMarkdown() {
      if (this.isDeleting) return;

      this.renderedContent = this.md.render(this.currentText);
      this.extractTags();
      this.forceRerender();

      this.$emit('save', {
        id: this.id,
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        text: this.currentText,
        tags: this.currentTags
      });
    },
    extractTags() {
      const tagRegex = /@(\w+)/g;
      const matches = [...this.currentText.matchAll(tagRegex)];
      this.currentTags = matches.map(match => match[1]);

      this.renderedContent = this.renderedContent.replace(tagRegex, '').trim();
      this.currentText = this.currentText.replace(tagRegex, '').trim();
    },
    restoreTags() {
      if (this.currentTags.length) {
        this.currentText += "\n\n" + this.currentTags.map(tag => `@${tag}`).join(' ');
      }
    },
    editMarkdown() {
      this.restoreTags();
      this.$refs.editable.innerText = this.currentText;
    },
    forceRerender() {
      this.componentKey++;
    },
    handleDelete(event) {
      if (event.key === "Delete") {
        if (confirm('Are you sure you want to delete this note?')) {
          event.preventDefault();
          this.isDeleting = true;
          this.$emit('delete', this.id);
        }
      }
    }
  },
  watch: {
    text(newVal) {
      if (this.isDeleting) return;
      this.currentText = newVal;
      this.applyMarkdown();
    }
  }
}
</script>

<style scoped>
.note-container {
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 15px;
  background-color: var(--main-background-color);
  z-index: 10;
}

.editable-area {
  width: 100%;
  height: 100%;
  border: none;
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  overflow-y: auto;
}

.tags-container {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  flex-wrap: wrap;
}

.tag {
  background-color: #e0e0e0f1;
  color: #333;
  border-radius: 999px;
  padding: 4px 8px;
  margin-right: 4px;
  font-size: 12px;
}
</style>
