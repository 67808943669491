<template>
  <div>
    <h2>Process</h2>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>PM ID</th>
          <th>Status</th>
          <th>CPU (%)</th>
          <th>Memory (MB)</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="process in processes" :key="process.pm_id">
          <td>{{ process.name }}</td>
          <td>{{ process.pm_id }}</td>
          <td>{{ process.status }}</td>
          <td>{{ process.cpu }}</td>
          <td>{{ (process.memory / 1024 / 1024).toFixed(2) }}</td>
          <td>
            <button @click="startProcess(process.name)" :disabled="process.status === 'online'">Start</button>
            <button @click="stopProcess(process.pm_id)" :disabled="process.status !== 'online'">Stop</button>
            <button @click="restartProcess(process.pm_id)">Restart</button>
            <button @click="fetchLogs(process.pm_id)">Logs</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Logs Modal -->
    <div v-if="showLogs" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeLogs">&times;</span>
        <h3>Logs for Process PM ID: {{ currentPmId }}</h3>
        <h4>Standard Output (stdout):</h4>
        <pre>{{ logs.stdout }}</pre>
        <h4>Error Output (stderr):</h4>
        <pre>{{ logs.stderr }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Process',
  data() {
    return {
      processes: [],
      showLogs: false,
      logs: {},
      currentPmId: null,
    };
  },
  methods: {
    async fetchProcesses() {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/processes`, {
          headers: {
            'authorization': localStorage.getItem('token'),
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching processes: ${response.statusText}`);
        }

        const data = await response.json();
        this.processes = data;
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    },
    async startProcess(name) {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/processes/start`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token'),
          },
          body: JSON.stringify({ name }),
        });

        if (!response.ok) {
          throw new Error(`Error starting process: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(data.message);
        await this.fetchProcesses();
      } catch (error) {
        console.error('Error starting process:', error);
      }
    },
    async stopProcess(pm_id) {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/processes/stop`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token'),
          },
          body: JSON.stringify({ pm_id }),
        });

        if (!response.ok) {
          throw new Error(`Error stopping process: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(data.message);
        await this.fetchProcesses();
      } catch (error) {
        console.error('Error stopping process:', error);
      }
    },
    async restartProcess(pm_id) {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/processes/restart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token'),
          },
          body: JSON.stringify({ pm_id }),
        });

        if (!response.ok) {
          throw new Error(`Error restarting process: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(data.message);
        await this.fetchProcesses();
      } catch (error) {
        console.error('Error restarting process:', error);
      }
    },
    async fetchLogs(pm_id) {
      try {
        const response = await fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/processes/${pm_id}/logs`, {
          headers: {
            'authorization': localStorage.getItem('token'),
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching logs: ${response.statusText}`);
        }

        const data = await response.json();
        this.logs = data;
        this.currentPmId = pm_id;
        this.showLogs = true;
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    },
    closeLogs() {
      this.showLogs = false;
      this.logs = {};
      this.currentPmId = null;
    },
  },
  mounted() {
    this.fetchProcesses();
  },
};
</script>

<style scoped>
/* Add your styles here */
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  border-bottom: 2px solid #ddd;
  padding: 8px;
}

tbody td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

button {
  margin-right: 5px;
  width: fit-content;
  padding: 0px 10px;
  height: 40px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  border-radius: 4px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  cursor: pointer;
}
</style>
