<template>
  <div class="timeline">
    <div class="event" v-for="(event, index) in events" :key="index">
      <div v-if="index !== 0" class="line" style="top: -20px; bottom: 50%;"></div>
      <div class="dot"></div>
      <div v-if="index !== events.length - 1" class="line" style="top: 50%; bottom: 0;"></div>
      <div class="content">
        <div class="title">{{ event.title }}</div>
        <div class="time">{{ event.timeDescription }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventTimeline',
  props: {
    events: {
      type: Array,
      default: () => [],
      required: true
    }
  }
}
</script>

<style scoped>
.timeline {
  position: relative;
  padding-left: 20px; /* Space for the line and dot */
}

.event {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Adjust based on your design */
}

.line {
  position: absolute;
  left: 9px; /* Center on the dot */
  width: 2px; /* Line thickness */
  background-color: #fff; /* Line color, change as needed */
  z-index: 0;
}

.dot {
  position: absolute;
  left: 4px; /* Align with the line */
  height: 12px; /* Dot size */
  width: 12px; /* Dot size */
  background-color: #fff; /* Dot color, change as needed */
  border-radius: 50%; /* Make it round */
  z-index: 1;
}

.content {
  margin-left: 30px; /* Adjust this value to give space for the line and dot */
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  color: #fff; /* Adjust text color as needed */
}

.time {
  font-size: 14px;
  color: #bbb; /* Adjust text color as needed */
}
</style>
