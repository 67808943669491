<template>
  <div>
    <h2>Settings</h2>
    <label for="file-upload" class="button custom-file-upload glowing">
      Choose File
    </label>
    <input id="file-upload" type="file" @change="handleImageUpload">
    <br>
    <label for="color-picker" class="button custom-color-picker glowing">
      Pick Color
    </label>
    <input type="color" id="color-picker" v-model="accentColor" @input="updateAccentColor">
    <custom-button
      :button-text="'Reset Settings'"
      :button-class="'button glowing'"
      @click="resetSettings"
    ></custom-button>
  </div>
</template>

<script>
import CustomButton from '../elements/CustomButton.vue';

export default {
  components: {
    CustomButton,
  },

  data() {
    return {
      backgroundImage: null,
      accentColor: '#8eafcc',
      accentTextColor: '#fff'
    };
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        if (file.size > 3000000) {
          alert('File size must be less than 3MB');
          return;
        }

        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.backgroundImage = e.target.result;
          localStorage.setItem('backgroundImage', this.backgroundImage);
          this.updateBodyBackground();
        };
      }
    },
    updateBodyBackground() {
      document.body.style.backgroundImage = `url('${this.backgroundImage}')`;
    },
    updateAccentColor() {
      localStorage.setItem('accentColor', this.accentColor);
      localStorage.setItem('accentTextColor', this.accentTextColor);
      document.documentElement.style.setProperty('--accent-color', this.accentColor);
      const r = parseInt(this.accentColor.slice(1, 3), 16);
      const g = parseInt(this.accentColor.slice(3, 5), 16);
      const b = parseInt(this.accentColor.slice(5, 7), 16);
      const brightness = Math.sqrt((r * r * 0.299) + (g * g * 0.587) + (b * b * 0.114));
      this.accentTextColor = brightness > 130 ? '#000' : '#fff';
      document.documentElement.style.setProperty('--accent-text-color', this.accentTextColor);
    },
    resetSettings() {
      localStorage.removeItem('backgroundImage');
      localStorage.removeItem('accentTextColor');
      this.backgroundImage = null;
      this.accentColor = '#8eafcc';
      this.accentTextColor = '#fff';
      document.body.style.backgroundImage = '';
      document.documentElement.style.setProperty('--accent-color', this.accentColor);
      document.documentElement.style.setProperty('--accent-text-color', this.accentTextColor);
    },
  },
  mounted() {
    this.accentColor = document.documentElement.style.getPropertyValue('--accent-color');
    this.accentTextColor = document.documentElement.style.getPropertyValue('--accent-text-color');
    const storedImage = localStorage.getItem('backgroundImage');
    if (storedImage) {
      this.backgroundImage = storedImage;
      this.updateBodyBackground();
    }
  },
};
</script>

<style scoped>
.button {
  margin-top: 20px;
  width: auto;
  border: 1px solid #fff;
}

.custom-file-upload,
.custom-color-picker {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
  font-size: 18px;
  font-family: "Space Grotesk", sans-serif;
}

.custom-color-picker {
  background-color: var(--accent-color);
  color: var(--accent-text-color);
}

.custom-color-picker:hover {
  background-color: var(--accent-color);
  color: var(--accent-text-color);
}

input[type="file"] {
  display: none;
}

input[type="color"] {
  display: inline-block;
  width: auto;
  border: none;
  height: 34px;
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin-top: 10px;
  margin-left: 10px;
  opacity: 0;
}
</style>
