import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './styles/fonts.css';
import './styles/style.css';

import DraggableResizableVue from 'draggable-resizable-vue3'
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

const app = createApp(App);
app.use(router);
app.use(DraggableResizableVue);
app.use(Vue3ColorPicker);
app.mount('#app');
