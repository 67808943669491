<template>
  <button @click="handleClick" :class="buttonClass">
    <img v-if="buttonIcon" :src="buttonIcon" alt="icon" class="icon" />
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    buttonClass: {
      type: String,
      default: 'button',
    },
    buttonIcon: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style>
button {
  padding: 10px 20px;
  border: none;
  border-radius: 13px;
  background-color: transparent;
  text-align: left;
  width: 100%;
  height: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-family: "Space Grotesk", sans-serif;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--hover-color);
}

button.active {
  background-color: var(--main-background-color)
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-bottom: -5px;
}
</style>