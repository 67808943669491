<template>
  <div>
    <h2>Example</h2>
    <!-- Your Dashboard content goes here -->
  </div>
</template>

<script>
export default {
// Settings component logic goes here
};
</script>