<template>
  <div>
    <h2>Home</h2>
    <div class="home-content">
      <div class="header-container">
        <div class="widget">
          <div class="left">
            <img :src="weatherIcon" alt="Weather icon">
          </div>
          <div class="right">
            <h3>{{ city }}</h3>
            <p>{{ temperature }} °C, {{ weatherString }}</p>
          </div>
        </div>
        <div class="widget">
          <div class="left">
            <img :src="timeSvg" width="40" height="40" alt="Time icon">
          </div>
          <div class="right">
            <h3>{{ getCurrentDate() }}</h3>
            <p>{{ currentTime }}</p>
          </div>
        </div>
      </div>
      <EventTimeline :events="myEvents" />
    </div>
  </div>
</template>

<script>
import EventTimeline from '../elements/Timeline.vue';
import timeSvg from '../../assets/time.svg';

export default {
  components: {
    EventTimeline
  },
  data() {
    return {
      temperature: null,
      weatherIconUrl: null,
      weatherString: null,
      city: null,
      currentTime: null,
      timeSvg: timeSvg,

      myEvents: [
        { title: 'Software updated', timeDescription: '1 week ago' },
        { title: 'Device created', timeDescription: '2 weeks ago' },
      ]
    };
  },
  mounted() {
    this.fetchWeather();
    this.getCurrentTime();
    this.updateTime();
  },
  methods: {
    async getUserLocation() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    async fetchWeather() {
      let position;

      const savedWeatherData = localStorage.getItem('weatherData');
      if (savedWeatherData) {
        const parsedData = JSON.parse(savedWeatherData);
        this.temperature = parsedData.temperature;
        this.weatherIcon = parsedData.weatherIcon;
        this.weatherString = parsedData.weatherString;
        this.city = parsedData.city;
      }

      try {
        position = await this.getUserLocation();
      } catch (error) {
        console.log('Geolocation error: ', error);
      }

      if (!position) {
        console.log('No position found, using default location');
        position = {
          coords: {
            latitude: 43.610,
            longitude: 3.876,
          },
        };
      }

      fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=${process.env.VUE_APP_OPENWEATHER_API_KEY}`)
        .then((response) => response.json())
        .then((data) => {
          this.temperature = data.main.temp;
          this.temperature = (this.temperature - 273.15).toFixed(2)
          this.weatherIcon = require(`@/assets/weather-icons/${data.weather[0].icon}.svg`);
          this.weatherString = data.weather[0].main;
          this.city = data.name;

          const weatherData = {
            temperature: this.temperature,
            weatherIcon: this.weatherIcon,
            weatherString: this.weatherString,
            city: this.city,
          };
          localStorage.setItem('weatherData', JSON.stringify(weatherData));
      });
    },
    updateTime() {
      setInterval(() => {
        this.getCurrentTime();
      }, 1000);
    },
    getCurrentTime() {
      const date = new Date();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },
    getCurrentDate() {
      const date = new Date();
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return `${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}`;
    }
  },
};
</script>

<style>

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.column {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}

.column p {
  margin: 0;
  display: flex;
  align-items: baseline;
}

.widget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--main-background-color);
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba( 0, 0, 0, 0.15 );
  height: 60px;
}

.widget h3 {
  margin: auto;
  width: max-content;
}

.widget .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widget .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.widget .left,
.widget .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.widget .left p,
.widget .right p {
  margin: 0;
  display: flex;
  align-items: baseline;
}

</style>