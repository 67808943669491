<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div>
    <h2>Notes</h2>
    <div class="actions">
      <button @click="addNote" class="button glowing">Create a new Note</button>
      <div class="select-wrapper">
        <select v-model="selectedTag" @change="filterNotes" class="tag-select glowing">
          <option value="">Select a tag</option>
          <option v-for="tag in allTags" :key="tag" :value="tag">{{ tag }}</option>
        </select>
      </div>
      <button v-if="selectedTag" @click="clearFilter" class="button clear-filter">Clear Filter</button>
    </div>
    <note-component
      v-for="note in (selectedTag ? filteredNotes : notes)"
      :key="note.id"
      :id="note.id"
      :initialX="note.x"
      :initialY="note.y"
      :initialWidth="note.width"
      :initialHeight="note.height"
      :text="note.text"
      :tags="note.tags"
      @delete="handleDelete"
      @save="handleSave"
    ></note-component>
  </div>
</template>


<script>
import NoteComponent from '../elements/Note.vue'

export default {
  components: {
    NoteComponent
  },
  data() {
    return {
      notes: [],
      filteredNotes: [],
      allTags: [],
      selectedTag: ''
    };
  },
  mounted() {
    this.loadNotes();
  },
  methods: {
    addNote() {
      const newNote = {
        id: Date.now(),
        x: 100,
        y: 100,
        width: 200,
        height: 150,
        text: '',
        tags: []
      };
      this.notes.push(newNote);
      this.handleSave(newNote);
    },
    handleDelete(noteId) {
      fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/delete-note`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify({ id: noteId })
      })
      .then(response => response.json())
      .then(() => {
        this.notes = this.notes.filter(note => note.id !== noteId);
      })
      .catch(error => console.error('Error deleting note:', error));
    },
    handleSave(noteData) {
      fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/save-note`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify({
          ...noteData,
          tags: noteData.tags || []
        })
      })
      .then(response => response.json())
      .catch(error => console.error('Error saving note:', error));
    },
    loadNotes() {
      fetch(`https://${process.env.VUE_APP_SERVER_IP}:5301/load-notes`, {
        headers: { 'authorization': localStorage.getItem('token') }
      })
      .then(response => response.json())
      .then(data => {
        this.notes = data.map(note => ({
          ...note,
          tags: note.tags || []
        }));
        this.filteredNotes = this.notes;
        this.collectTags();
      })
      .catch(error => console.error('Error loading notes:', error));
    },
    collectTags() {
      const tagsSet = new Set();
      this.notes.forEach(note => {
        note.tags.forEach(tag => {
          tagsSet.add(tag);
        });
      });
      this.allTags = Array.from(tagsSet);
    },
    filterNotes() {
      if (this.selectedTag) {
        this.filteredNotes = this.notes.filter(note => note.tags.includes(this.selectedTag));
      } else {
        this.filteredNotes = this.notes;
      }
    },
    clearFilter() {
      this.selectedTag = '';
      this.filteredNotes = this.notes; // Reset to show all notes
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  align-items: center;
  gap: 5px;
}

.button, .clear-filter, .tag-select {
  width: auto;
  border: 1px solid #fff;
  margin-right: 10px;
  cursor: pointer;
}

.clear-filter {
  border: 1px solid #ff5151;
  color: #fff;
}

.clear-filter:hover {
  background-color: #ff5151;
  box-shadow: 0 0 30px 0px #ff515139;
  transition: ease-in-out 0.2s;
}
.select-wrapper {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

.tag-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: auto;
  padding: 10px 20px;
  height: 50px;
  border-radius: 10px;
  color: #fff;
  background-color: transparent;
  font-size: 18px;
  font-family: "Space Grotesk", sans-serif;
  cursor: pointer;
}

.tag-select option {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
</style>