import { createRouter, createWebHistory } from 'vue-router';

import Home from './components/pages/Home.vue';
import Notes from './components/pages/Notes.vue';
import Files from './components/pages/Files.vue';

import Stats from './components/pages/Stats.vue';
import Settings from './components/pages/Settings.vue';
import Terminal from './components/pages/Terminal.vue';
import Example from './components/pages/Example.vue';
import Process from './components/pages/Process.vue';
import Airdrop from './components/pages/Airdrop.vue';

const routes = [
    { path: '/', component: Home, name: 'home' },
    { path: '/notes', component: Notes, name: 'notes' },
    { path: '/files', component: Files, name: 'files' },
    { path: '/airdrop', component: Airdrop, name: 'airdrop' },
    { path: '/stats', component: Stats, name: 'stats' },
    { path: '/settings', component: Settings, name: 'settings' },
    { path: '/terminal', component: Terminal, name: 'terminal' },
    { path: '/process', component: Process, name: 'process' },
    { path: '/example', component: Example, name: 'example' },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;